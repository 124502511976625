// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

// Additional imports
import './bootstrap_custom.js';
import '../stylesheets/application.scss';
import './bootstrap-show-password.js';
import 'chartkick/chart.js';
import 'select2';
import 'select2/dist/css/select2.css';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
});

document.addEventListener('turbolinks:load', () => {
  $('.select2').select2();
});

document.addEventListener('turbolinks:load', () => {
  $('.select2-ajax').select2({
    ajax: {
      url: $('.select2-ajax').data('source'),
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      data: function (params) {
        console.log($('#coupon_country_id').val(), params);
        return {
          term: params.term, // search term
          country_id: $('#coupon_country_id').val(),
        };
      },
      processResults: function (data) {
        return {
          results: data,
        };
      },
      cache: true,
    },
    minimumInputLength: 1,
    placeholder: 'Search for an organization',
  });

  // Handle country select change event
  $('#coupon_country_id').on('change', function () {
    $('#coupon_organization_ids').val(null).trigger('change');
  });
});

window.onbeforeprint = function () {
  let chart = Chartkick.charts['chart-1'];
  chart.redraw();
};

window.onafterprint = function () {
  let chart = Chartkick.charts['chart-1'];
  chart.redraw();
};
